import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl, sanityFileUrl } from '../../../utils/format'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

SwiperCore.use([Navigation])

export default ({ customers }) => {
  if (!customers || customers.isHide) return null

  const [selectedReview, setSelectedReview] = useState(null)

  const buildSliderSettings = () => {
    return {
      spaceBetween: 64,
      slidesPerView: 4,
      simulateTouch: true,
      navigation: {
        nextEl: `.swiper-btn-next`,
        prevEl: `.swiper-btn-prev`,
        disabledClass: `${styles.btnDisabled}`,
      },
      className: styles.slider,
      breakpoints: {
        0: {
          slidesPerView: 1.3,
          spaceBetween: 16,
        },
        701: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        901: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
      },
    }
  }

  const hidePreview = (e, key) => {
    const video = e.target
    video.play()
    const image = document.querySelector(`[data-id="${key}"]`)
    image.classList.add(styles.imageHidden)
  }

  const modalLoaded = () => {
    const closeBtn = document.querySelector(`.${styles.close}`)
    closeBtn.classList.remove(styles.closeHidden)
  }

  const activeReview = useMemo(() => {
    let review = null

    if (selectedReview) {
      customers.items.map(item => {
        if (item._key === selectedReview) {
          review = item
        }
      })
    }

    return review
  }, [selectedReview])

  return (
    <div className={styles.customers} id="testimonials">
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: customers.title }} />

        <div className={styles.sliders}>
          <div className={styles.line}>
            <Swiper {...buildSliderSettings()}>
              {customers.items &&
                customers.items.map(item => (
                  <SwiperSlide className={styles.item} key={item._key}>
                    <div className={styles.info} onClick={() => setSelectedReview(item._key)}>
                      <img
                        src={sanityImageUrl(item.image)}
                        alt={item.image?.caption}
                        className={styles.image}
                        data-id={item._key}
                      />
                      <video
                        className={styles.video}
                        preload="auto"
                        muted
                        loop
                        playsInline
                        autoPlay
                        onLoadedData={e => hidePreview(e, item._key)}
                      >
                        <source src={sanityFileUrl(item.videoPreview)} type="video/mp4" />
                      </video>
                      <div className={styles.name}>{item.name}</div>
                    </div>
                    <div className={styles.protocol}>
                      <img
                        src={sanityImageUrl(item.protocolImage)}
                        alt={item.protocolImage?.caption}
                        className={styles.protocolImage}
                      />
                      <Link to={item.protocolUrl} className={styles.protocolInfo}>
                        <p className={styles.protocolName} dangerouslySetInnerHTML={{ __html: item.protocolName }} />
                        <p className={styles.protocolMotto} dangerouslySetInnerHTML={{ __html: item.protocolMotto }} />
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>

            <div className={cx(styles.btnPrev, `swiper-btn-prev`)} />
            <div className={cx(styles.btnNext, `swiper-btn-next`)} />
          </div>
        </div>
      </div>

      {selectedReview && (
        <div className={styles.review}>
          <div className={styles.videoBox}>
            <div className={cx(styles.close, styles.closeHidden)} onClick={() => setSelectedReview(null)} />
            <div className={styles.videoBoxPreview}>
              <div className={styles.loader} />
            </div>
            <video className={styles.videoBoxSrc} playsInline autoPlay onLoadedData={modalLoaded}>
              <source src={sanityFileUrl(activeReview.videoFull)} type="video/mp4" />
            </video>
            <div className={styles.name}>{activeReview.name}</div>
          </div>
        </div>
      )}
    </div>
  )
}
