import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import Header from '../components/InfluencePage/Header'
import Products from '../components/InfluencePage/Products'
import Info from '../components/InfluencePage/Info'
import Customers from '../components/InfluencePage/Customers'
import Reviews from '../components/InfluencePage/Reviews'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Products products={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <Info info={data.info} protocols={data.settings?.protocols?.items} />,
      priority: data.info?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
